<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#9e9e9e"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.333.5a1 1 0 011 1v.667H14a2.667 2.667 0 012.667 2.666v10A2.667 2.667 0 0114 17.5H4a2.667 2.667 0 01-2.667-2.667v-10A2.667 2.667 0 014 2.167h.667V1.5a1 1 0 112 0v.667h4.666V1.5a1 1 0 011-1zm2.334 4.333v2.334H3.333V4.833c0-.368.299-.666.667-.666h.667v.666a1 1 0 102 0v-.666h4.666v.666a1 1 0 102 0v-.666H14c.368 0 .667.298.667.666zm0 4.334H3.333v5.666c0 .369.299.667.667.667h10a.667.667 0 00.667-.667V9.167zm-9 1.333a1 1 0 00-1 1v1.667a1 1 0 001 1h1.666a1 1 0 001-1V11.5a1 1 0 00-1-1H5.667z"
    />
  </svg>
</template>

<script>
    export default {
        name: "Birthday"
    }
</script>
